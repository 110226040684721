import _ from 'lodash';
export default function component_support(){
	(function($) {
	$(function(){
		if($('.js--iframe iframe').length) {
			$('.js--iframe iframe').wrap('<div class="set_movie__iframe">');
		}

		_.each(document.querySelectorAll('.article_body__section table'),(item,index)=>{
			let table_r = document.createElement('div')
			table_r.setAttribute("class", "table-radius");

			item.parentNode.insertBefore(table_r,item);
			table_r.appendChild(item);
		});

		/**
		 * modal
		 */
		$(document).on('click','.modal-next',function(){
			if($('.pp_nav .pp_arrow_next').length > 0){
				$('.pp_nav .pp_arrow_next').click();
			}
			return false;
		});

		$(document).on('click','.modal-prev',function(){
			if($('.pp_nav .pp_arrow_previous').length > 0){
				$('.pp_nav .pp_arrow_previous').click();
			}
			return false;
		});

		$(document).on('click','.modal-close',function(){
			if($('.pp_details .pp_close').length > 0){
				$('.pp_details .pp_close').click();
			}
			return false;
		});

	});
	})(jQuery);
}
