import Responsive from "./Responsive";
import SmoothScroll from "./SmoothScroll";
import Accordion from "./Accordion";
import Tab from "./Tab";
import SpNavi from "./SpNavi";
import ToTop from "./ToTop";
import DropDownMenu from "./DropDownMenu";
import BlockLink from "./BlockLink";
import Gmap from "./Gmap";
import bowser from "bowser";
import matchheight from "jquery-match-height";
import component_support from "./component-support";
import izimodal from "izimodal";

component_support();
const r = new Responsive();

$(".inactive").on("click", () => {
	return false;
});
/**
 * designed scrollber
 */
import PerfectScrollbar from "perfect-scrollbar";

$(".js-desined-scrollbar").each(function () {
	let ps = new PerfectScrollbar($(this)[0], {
		maxScrollbarLength: 40,
	});

	$(this).find(".ps__rail-x, .ps__rail-y").attr("aria-hidden", true);
});

if (bowser.msie === true) {
	let version = "ie_" + Math.floor(bowser.version);
	$("body").addClass("ie " + version);
} else if (bowser.msedge === true) {
	$("body").addClass("edge");
} else if (bowser.firefox === true) {
	$("body").addClass("ff");
} else if (bowser.tablet === true) {
	$("body").addClass("tablet");
} else if (bowser.safari === true) {
	$("body").addClass("safari");
}

if (bowser.mobile === true) {
	$("body").addClass("device--mobile");
	if (bowser.android === true) {
		$("body").addClass("device--mobile--android");
	}
}

let os,
	ua = navigator.userAgent;
if (ua.match(/Win(dows )?NT 10\.0/)) {
	os = "win10";
} else if (ua.match(/Win(dows )?NT 6\.3/)) {
	os = "win8_1";
} else if (ua.match(/Win(dows )?NT 6\.2/)) {
	os = "win8";
} else if (ua.match(/Win(dows )?NT 6\.1/)) {
	os = "win7";
} else {
	os = "";
}

$("body").addClass(os);
/* ===============================================
Check responsive state
=============================================== */
// $(window).on('resize',function(){
// check is responsive;
// 	console.log(r.state()());
// });
// check is mobile;
// alert(r.isPhone());
/* ===============================================
SP Navi
=============================================== */
//通常盤
let param = {
	target: "#spnavi",
	trigger: ".btn_sp_navi",
	filter: "resposive_flg",
	speed: 200,
};
const spnavi = new SpNavi(param);
let spnav_accordion_1 = (scroller) => {
	$("#scroller .inner .sp_main_navi > ul > li > a").off();
	$("#scroller .inner .sp_main_navi > ul > li > a").on("click", function () {
		let $parent = $(this).parent("li");
		// let $child = $(this).next('.child');
		let $child = $(this).next(".sub-menu");
		let status = $child.css("display");
		if ($child.length > 0) {
			if (status == "block") {
				$child.slideUp(300, function () {
					scroller.refresh();
				});
				$parent.removeClass("active");
			} else {
				$child.slideDown(300, function () {
					scroller.refresh();
				});
				$parent.addClass("active");
			}

			return false;
		}
	});
	$("#scroller .menu-item-has-children.current-menu-ancestor").addClass(
		"active"
	);
	$("#scroller .menu-item-has-children.current-menu-ancestor .sub-menu").css(
		"display",
		"block"
	);
};
$(window).on("load", function () {
	spnavi.exec(spnav_accordion_1);
});

/* ===============================================
box link
=============================================== */
// let box_link = new BlockLink();
// box_link.exec('.box_link');
/* ===============================================
Smooth Scroll
=============================================== */
const sms = new SmoothScroll();
sms.exec();
/* ===============================================
To Top
show/hide toggle
=============================================== */
const totop = new ToTop("#totop", "#global_footer", 100, 400);
totop.exec();

/**
 * 外部リンク・PDF・Word・Exlsに_blankの自動付与
 *
 */
const link_icon = require("./link_icon");
link_icon();

/* ===============================================
Accordion

markp:
<dl>
	<dt></dt>
	<dd></dd>
	<dt></dt>
	<dd></dd>
	<dt></dt>
	<dd></dd>
</dl>
=============================================== */
const ac = new Accordion($(".panel-title-wrap"));
ac.exec("only_sp");
const ac2 = new Accordion($(".js-ac-open"));
ac2.exec();
$(".js-ac-close").on("click", function () {
	$(this).parents(".js-ac-target").prev().click();
});

/**
 * change root font-size
 */
/**
 * * click funtion
 */
$(".js-fontsize button").on("click", function () {
	let fz = $(this).data("fontsize");

	$(this).parent().find("button").removeClass("active");
	$(this).addClass("active");

	$("html")
		.removeClass("fz16 fz18")
		.css("font-size", `${fz}px`)
		.addClass(`fz${fz}`);
	document.cookie = `fz=${fz}`;
});
/**
 * * check cookie and set font-size
 */
const cookie = document.cookie.split(";");
let current_fontsize = 0;
cookie.forEach(function (value) {
	let content = value.split("=");
	if (content[0].match(/fz$/) !== null) {
		current_fontsize = content[1];
	}
});
if (current_fontsize > 0) {
	$(`.js-fontsize button[data-fontsize="${current_fontsize}"]`).click();
}

$(".sp-zoom").on("click", function (e) {
	if (!r.isPC()) {
		console.log(r.isPC());
		let url = "";
		url =
			$(this).find("img").length > 0
				? $(this).find("img").attr("src")
				: $(this).attr("src");
		if (url) {
			e.preventDefault();
			window.open(url);
		}
	}
});

/* ===============================================
#	global nav
=============================================== */
//pc
$(".header-mainnavi-items > .menu-item-has-children > a").on(
	"click",
	function (e) {
		$(this).toggleClass("mod-on");
		$(this).next(".sub-menu").slideToggle();
		return false;
	}
);

/* ===============================================
# seach sp
=============================================== */
$(".js-serach-sp").on("click", function () {
	$(".search-link-sp").slideToggle();
	return false;
});

/* ===============================================
#	much height
=============================================== */
$(
	".set_images .article_body__column--span3 .set_imagetext__textbox__title"
).matchHeight();

/* ===============================================
#	izimodal
=============================================== */
if ($(".js-izimodal").length) {
	$(".js-izimodal").iziModal({
		width: 960,
		navigateArrows: false,
	});
}
