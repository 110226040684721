import Responsive from './Responsive';
class Accordion{
	constructor(target) {
		this.target = target;
	}

	exec(status){
		const r = new Responsive();
		$(this.target).on('click',function(e){
			e.preventDefault();
			console.log(r.isPC());
			if(status == 'only_sp' && r.isPC()){
				return false;
			}
			let next = $(this).next();
			let state = next.css('display');
			if(state == 'block'){
				$(this).removeClass('active');
				next.slideUp(200);
			}else{
				$(this).addClass('active');
				next.slideDown(200);
			}
		});
	}
}
module.exports = Accordion;
